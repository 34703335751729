<template>
  <div class="bg-[#F2F3F8] rounded-lg border-2 p-1">
    <div class="flex justify-center items-center ">
      <router-link :to="{'name': 'analytics'}">
        <div :class="[$route.name === 'analytics' ? '!bg-white' : '']"
             class="px-4 py-1 rounded-lg font-poppins font-semibold flex justify-center items-center gap-x-2 cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <mask id="path-1-inside-1_2121_4966" fill="white">
              <rect y="0.762329" width="16" height="3.8093" rx="0.888862"/>
            </mask>
            <rect y="0.762329" width="16" height="3.8093" rx="0.888862" stroke="#757A8A" stroke-width="2.31104"
                  mask="url(#path-1-inside-1_2121_4966)"/>
            <mask id="path-2-inside-2_2121_4966" fill="white">
              <rect y="6.09534" width="16" height="3.8093" rx="0.888862"/>
            </mask>
            <rect y="6.09534" width="16" height="3.8093" rx="0.888862" stroke="#757A8A" stroke-width="2.31104"
                  mask="url(#path-2-inside-2_2121_4966)"/>
            <mask id="path-3-inside-3_2121_4966" fill="white">
              <rect y="11.4283" width="16" height="3.8093" rx="0.888862"/>
            </mask>
            <rect y="11.4283" width="16" height="3.8093" rx="0.888862" stroke="#757A8A" stroke-width="2.31104"
                  mask="url(#path-3-inside-3_2121_4966)"/>
          </svg>
          <p :class="[$route.name === 'analytics' ? '!text-[#2560D7]' : '']" class="text !text-[14px] !font-semibold">
            List</p>
        </div>
      </router-link>
      <div @click.prevent="navigateToDetailView()" :class="[$route.name === 'analytics_detail' ? '!bg-white' : '']"
           class="px-4 py-1 rounded-lg font-poppins font-semibold flex justify-center items-center gap-x-2 cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M9.27785 2.2963C9.27785 1.7338 9.73386 1.27779 10.2964 1.27779H14.3705C14.933 1.27779 15.389 1.7338 15.389 2.2963V13.7037C15.389 14.2662 14.933 14.7222 14.3705 14.7222H10.2964C9.73386 14.7222 9.27785 14.2662 9.27785 13.7037V2.2963Z"
            stroke="#757A8A" stroke-width="1.22221"/>
          <rect x="0.610862" y="1.27779" width="6.11112" height="5.44445" rx="1.01851" stroke="#757A8A"
                stroke-width="1.22221"/>
          <rect x="0.610862" y="9.27779" width="6.11112" height="5.44445" rx="1.01851" stroke="#757A8A"
                stroke-width="1.22221"/>
        </svg>
        <a class="btn_link">
          <p :class="[$route.name === 'analytics_detail' ? '!text-[#2560D7]' : '']"
             class="text !text-[14px] !font-semibold">Graph</p>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import {analyticsTypes} from '@/state/modules/mutation-types'

export default {
  mounted() {
  },
  methods: {
    navigateToDetailView() {
      this.$store.commit(analyticsTypes.RESET_ANALYTICS_LINK_FILTER)
      this.$store.commit(analyticsTypes.RESET_ANALYTICS_BRAND_FILTER)
      this.$store.commit(analyticsTypes.RESET_ANALYTICS_CAMPAIGN_FILTER)
      this.$router.push({name: 'analytics_detail'})
    }
  }
}
</script>
<style lang="less">
.toggle_btn_inner {
  width: 100% !important;
}
</style>
